import React from "react";
import About from "./About.js";
import Projects from "./Projects.js";
import SubmitForm from "./SubmitForm";

const MainContent = () => {
  return (
    <section className="main-content">
      <About />
      <Projects />
      <SubmitForm />
    </section>
  );
};

export default MainContent;
