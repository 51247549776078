import React from "react";
import "../stylesheets/projectRight.css";

const ProjectRight = (props) => {
  return (
    <section className="project-right">
      <a href={props.link} className="project-right-backcard">
        <img
          src={props.img}
          alt="right-project"
          className="project-right-image"
        ></img>
      </a>
      <aside className="project-right-details">
        <h3 className="project-right-title">{props.title}</h3>
        <p className="project-right-desc">{props.desc}</p>
        <div className="project-links">
          <a href={props.link} target="_blank" rel="noreferrer noopener">
            <div className="project-link">LIVE APP</div>
          </a>
          {props.github !== "private" && (
            <a href={props.github} target="_blank" rel="noreferrer noopener">
              <div className="project-link">GITHUB</div>
            </a>
          )}
        </div>
      </aside>
    </section>
  );
};

export default ProjectRight;
