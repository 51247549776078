import React from "react";
import reactLogo from "../images/react.png";
import cssLogo from "../images/css.png";
import htmlLogo from "../images/html.png";
import pixelmatorLogo from "../images/pixelmator.svg";
import figmaLogo from "../images/Figma-Icon.svg";
import javascriptLogo from "../images/javascript.png";
import nodeLogo from "../images/node.png";
import "../stylesheets/skills.css";
import SkillBox from "./SkillBox";

const skills = [
  { name: "CSS", img: cssLogo, position: "right" },
  { name: "NextJS", position: "left", img: "next" },
  { name: "React", img: reactLogo, position: "left" },
  { name: "GatsbyJS", position: "left", img: "/gatsby.png" },
  { name: "Figma", img: figmaLogo, position: "right" },
  { name: "Pixelmator Pro", img: pixelmatorLogo, position: "right" },
  { name: "Typescript", position: "middle", img: "/typescript.png" },
  { name: "Javascript", img: javascriptLogo, position: "middle" },
  { name: "NodeJS", img: nodeLogo, position: "middle" },

  { name: "HTML", img: htmlLogo, position: "middle" },
];

const leftSkills = skills.filter((skill) => skill.position === "left");
const middleSkills = skills.filter((skill) => skill.position === "middle");
const rightSkills = skills.filter((skill) => skill.position === "right");

const Skills = () => {
  return (
    <section className="skills">
      <div className="skills-flex">
        <div className="skills-left">
          {leftSkills.map((skill, index) => {
            return (
              <SkillBox
                key={`${skill}-${index}`}
                name={skill.name}
                img={skill.img}
              />
            );
          })}
        </div>
        <div className="skills-middle">
          {middleSkills.map((skill, index) => {
            return (
              <SkillBox
                key={`${skill}-${index}`}
                name={skill.name}
                img={skill.img}
              />
            );
          })}
        </div>
        <div className="skills-right">
          {rightSkills.map((skill, index) => {
            return (
              <SkillBox
                key={`${skill}-${index}`}
                name={skill.name}
                img={skill.img}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
