import React from "react";
import "../stylesheets/projects.css";
import ProjectLeft from "./ProjectLeft.js";
import ProjectRight from "./ProjectRight.js";

const Projects = () => {
    return (
        <section id="projects" className="projects">
            <div className="header-container">
                <header className="projects-header">
                    <h2 className="projects-header-text">Projects</h2>
                    <div className="project-header-underline"></div>
                </header>
            </div>
            <ProjectLeft
                img="rehabs.png"
                title="Rehabs In"
                desc="A full stack web application that helps users find recovery centers given a certain location. I've also built in installable script for non-profits and health providers to offer the tool on their website. Built with NextJS, React, Supabase, and Contentful. Work in progress."
                github="private"
                link="https://rehabs-in.com"
                addModal
            />
            <ProjectRight
                img="apexApp.jpg"
                title="Rewardpex"
                desc="A full stack app that rewards Apex players for performing well. Built with React, Node, Express, and MongoDB. No longer supported - free heroku tier was sunset and migration to Netlify led to issues not worth resolving."
                github="https://github.com/KyleMo/Apex-Legends-Rewards-App"
                link="https://rewardpex.netlify.app/"
            />
            <ProjectLeft
                img="tenziesimage.jpg"
                title="Tenzies Speed Challenge"
                desc="Tenzies mini game with four difficulties, time tracker, and a public leaderboard. Make your attempt to take the #1 position on the leaderboards! Built with React, Node, Express, and MongoDB.  No longer supported - free heroku tier was sunset"
                github="https://github.com/KyleMo/Tenzies-App"
            />
        </section>
    );
};

export default Projects;
