import React from 'react';
import personOutline from '../images/outline.svg';
import Skills from './Skills.js';
import '../stylesheets/about.css'

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-header">
        <h2 className="about-header-text">About</h2>
        <div className="about-header-underline"></div>
      </div>
      <img alt="anon" className="about-image" src={personOutline}></img>
      <p className="about-description">
        As a technology enthusiast, I've always been rooted in learning more
        about programming. From college till now, areas that have particularly
        interested me include creation through code, the foundations of the web,
        and graphic design. However, what happens on my computer isn't the only passion in my life. Other interests include making memories with friends,
        beach volleyball, surfing, and more. If you would like to learn more about
        me reach out!
      </p>
      <Skills />
    </section>
  )
}

export default About;
