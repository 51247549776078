import React from 'react'
import Particles from "react-tsparticles";
import '../stylesheets/hero.css'

export default function Hero() {

  const options = {
    background: {
      color: "red"
    },
    fullScreen: {enable: false},
    fpslimit: 60,
    interactivity: {
      detectsOn: "canvas",
      events: {
        resize: true,
      }
    },

    particles: {
      color: {
          value: ["#5bc8f0","#ae4cf5","#5bc8f0","#5bc8f0","#5bc8f0","#5bc8f0","#5bc8f0","#5bc8f0"]
      },
      move: {
        enable: true,
        speed: .25,
        trail: {
          enable: true,
          length: 10
        }
      },
      number: {
        density: {
          enable: true,
          area: 1080
        },
        limit: 0,
        value: 70
      },
      opacity: {
        animation: {
          enable: true,
          minimumValue: 0.05,
          speed: .5,
          sync: false
        },
        random: {
          enable: true,
          minimumValue: 0.05,
          maximumValue: 0.1
        },
        value: 1
      },
      shape: {
        type: "circle"
      },
      size: {
        random: {
          enable: true,
          minimumValue: 1
        },
        value: 3,
      }
    }
  }

  return(
    <section id="hero" className="hero-container">
      <div className="hero-text">
        <h2>Hello, I'm <span>Kyle</span>.</h2>
        <h2>I'm a fullstack engineer.</h2>
      </div>
      <a className="hero-btn" href="#projects"><div>View my work &#x2193;</div></a>
      <Particles
        className="tsparticles"
        options={options}
      />
    </section>
  )
}
