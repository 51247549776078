import React from "react";
import "../stylesheets/submitForm.css";
import { send } from "@emailjs/browser";

const isValidEmail = (testString) => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(testString) ? true : false;
};

const SubmitForm = () => {
  const [sentStatus, setSentStatus] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({
    from_name: "",
    from_email: "",
    message: "",
  });
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidName, setInvalidName] = React.useState(false);
  const [invalidMessage, setInvalidMessage] = React.useState(false);

  const handleChange = (event) => {
    setSentStatus(false);
    setFormInfo((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const submitIfValidInputs = () => {
    if (formInfo.from_name === "") {
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }

    if (!isValidEmail()) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }

    if (formInfo.message === "") {
      setInvalidMessage(true);
    } else {
      setInvalidMessage(false);
    }

    if (
      isValidEmail(formInfo.from_email) &&
      formInfo.name !== "" &&
      formInfo.message !== ""
    ) {
      send("service_hu70wsl", "template_gvej03q", formInfo, "z8Oh_H8XPhnI9CJLR")
        .then(() => {
          setSentStatus(true);
        })
        .catch((err) => {
          setSentStatus(false);
          console.warn("FAILED...", err);
        });
    }
  };

  return (
    <section id="contact" className="contact">
      <div className="contact-header">
        <h2 className="contact-header-text">Contact</h2>
        <div className="contact-header-underline"></div>
      </div>
      <div className="contact-details">
        <p className="contact-instructions">
          Want to work together? Leave your details below.
        </p>
        <form className="contact-form">
          <input
            onChange={handleChange}
            value={formInfo.from_name}
            className={
              invalidName
                ? "contact-input-red contact-input contact-email"
                : "contact-input contact-name"
            }
            placeholder="Name"
            name="from_name"
            type="text"
          />
          <input
            onChange={handleChange}
            value={formInfo.from_email}
            className={
              invalidEmail
                ? "contact-input-red contact-input contact-email"
                : "contact-input contact-email"
            }
            name="from_email"
            placeholder="Email"
            type="text"
          />
          <textarea
            onChange={handleChange}
            value={formInfo.message}
            className={
              invalidMessage
                ? "contact-input-red contact-input contact-message"
                : "contact-input contact-message"
            }
            placeholder="Message"
            name="message"
          />
          <div className="contact-info-error">
            <div className="contact-error-messages">
              {sentStatus && (
                <p className="error">
                  Thanks for reaching out! I'll get back to you as soon as
                  possible.
                </p>
              )}
              {invalidName && (
                <p className="error">Please enter your full name.</p>
              )}
              {invalidEmail && (
                <p className="error">Please enter a valid email.</p>
              )}
              {invalidMessage && (
                <p className="error">Please enter a brief message.</p>
              )}
            </div>
            <button
              type="button"
              onClick={submitIfValidInputs}
              className="contact-submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SubmitForm;
