import React from "react";
import "../stylesheets/projectLeft.css";

const ProjectLeft = (props) => {
    return (
        <section className="project-left">
            <a href={props.link} className="project-left-backcard">
                <img
                    src={props.img}
                    alt="project-left"
                    className="project-left-image"
                ></img>
            </a>
            <aside className="project-left-details">
                <h3 className="project-left-title">{props.title}</h3>
                <p className="project-left-desc">{props.desc}</p>
                <div className="project-links">
                    {props.link && (
                        <a
                            href={props.link}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <div className="project-link">LIVE APP</div>
                        </a>
                    )}
                    {props.github !== "private" && (
                        <a
                            href={props.github}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <div className="project-link">GITHUB</div>
                        </a>
                    )}
                    {props.addModal && (
                        <button
                            className="project-link"
                            style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "rgba(0,0,0,0)",
                                borderBottom: "2px solid #5bc8f0",
                            }}
                            onClick={() => {
                                window.rehabsin.show();
                            }}
                        >
                            OPEN MODAL (from script)
                        </button>
                    )}
                </div>
            </aside>
        </section>
    );
};
export default ProjectLeft;
